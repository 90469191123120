.account-details {
  color: $blue-common;
  padding: 6px 12px 8px;
  display: flex;
  flex-direction: column;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Noto Sans,
    Ubuntu, Droid Sans, Helvetica Neue, sans-serif;

  .account-header {
    display: flex;
    gap: 5px;
    font-size: 14px;

    .user-img {
      width: 40px;
    }

    .username {
      color: #babdc1;
      font-size: 12px;
      margin-top: 5px;
    }
  }

  .seperator {
    background-color: #5e6c847f;
    width: 100%;
    height: 0.1px;
    align-self: center;
    margin-top: 20px;
    margin-bottom: 15px;
  }

  .btn-container {
    transition: background-color 0.15s;
    background-color: transparent;
    width: 100%;

    &:hover {
      background-color: #f6f6f7;
    }

    .btn-login-logout {
      background-color: transparent;
      height: 32px;
      width: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }
  }
}
