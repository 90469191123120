.attachment-preview {
  display: flex;
  gap: 16px;
  margin: 0 0 8px;

  .attachment-preview-img {
    background-repeat: no-repeat;
    background-size: contain;
    background-position: 50%;
    background-color: rgba(223, 215, 215, 0.259);
    border-radius: 3px;
    height: 80px;
    z-index: 1;
    width: 112px;
  }

  .attachment-details {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-grow: 1;
    justify-content: space-between;
    padding: 8px 0;
    font-size: 14px;
    color: #5e6c84;

    .attachment-name-and-options {
      .attachment-name {
        display: block;
        color: #172b4d;
        font-weight: 700;
        margin-block-end: 4px;
      }

      .delete-attachment {
        text-decoration: underline;

        &:hover {
          cursor: pointer;
          color: #172b4d;
        }
      }
    }

    .make-attachment-cover {
      display: block;
      text-decoration: underline;
      display: flex;
      align-items: center;
      gap: 10px;

      .svg-holder {
        font-size: 14px;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 8px;
        padding-left: 6px;

        svg {
          margin-top: 4px;
          font-size: 10px;
        }
      }

      &:hover {
        cursor: pointer;
        color: #172b4d;
      }
    }
  }
}
