.task-labels-list {
  width: 100%;
  display: flex;
  gap: 4px;
  flex-wrap: wrap;
  padding-top: 5px;

  &.large {
    margin-bottom: 4px;
  }

  .label-container {
    height: 16px;
  }

  .task-labels-preview {
    height: 8px;
    border-radius: 4px;
    min-width: 40px;
    transition: width, height 0.45s ease-out;
    display: flex;
    align-items: center;
    padding-inline-end: 8px;
  }

  .large {
    height: 16px;
    min-width: 56px;
  }

  .color-circle {
    height: 8px;
    width: 8px;
    border-radius: 50%;
    margin: 4px;
  }

  .label-title {
    font-size: 12px;
  }
}
