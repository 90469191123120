.add-item {
  background-color: #ebecf0;
  padding: 5px;
  border-radius: 3px;
  font-size: 14px;
  min-width: 272px;
  width: 272px;

  form {
    display: flex;
    flex-wrap: wrap;
  }

  textarea {
    border-radius: 3px;
    background-color: white;
    padding: 9px 8px;
    height: 34px;
    box-shadow: var(--ds-shadow-raised, 0 1px 0 #091e4240);
    border: none;
    width: 100%;
    margin-bottom: 8px;
  }

  button {
    font-size: 14px;
    border: none;
    padding: 6px 12px;
    height: 32px;
    border-radius: 3px;
  }

  button:first-of-type {
    background-color: #0079bf;
    color: white;
    margin-right: 4px;
  }

  .svg-holder {
    width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    display: inline-flex;
    svg {
      height: 16px;
      width: 16px;

      &:hover {
        cursor: pointer;
      }
    }
  }
}
