.side-menu-main-display {
  height: calc(100% - 48px);
  overflow: auto;

  &::-webkit-scrollbar {
    height: 8px;
    width: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #c6cbd4;
    border-radius: 3px;
    height: 12px;
  }

  &::-webkit-scrollbar-track {
    background-color: #e2e4e9;
    border-radius: 3px;
    margin-top: 6px;
    margin-bottom: 6px;
    height: 12px;
    border-bottom: 10px solid transparent;
  }
}
