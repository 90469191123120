$blue: #0079bf;

.attachment {
  p {
    padding: 0 12px;
    line-height: 32px;
    font-size: 0.875rem;
    color: #172b4d;
    margin: 8px 0;
  }

  input[type='file'] {
    opacity: 0;
    position: absolute;

    &:hover {
      cursor: pointer;
      background-color: #091e420a;
      & p {
        background-color: #091e420a;
      }
    }
  }

  .seperator {
    margin: 0 12px;
    border-bottom: 1px solid #091e4221;
  }

  label {
    color: #5e6c84;
    font-size: 0.75rem;
    font-weight: 700;
    display: block;
    margin-bottom: 4px;
    margin-top: 12px;
  }

  .input-container {
    padding: 0 12px;

    &:hover input {
      background-color: #ebecf0;
      box-shadow: inset 0 0 0 2px #dfe1e6;
    }
  }

  input {
    border: none;
    box-shadow: inset 0 0 0 2px #dfe1e6;
    padding: 8px 12px;
    font-size: 0.875rem;
    width: 100%;
    color: #172b4d;
    outline: none;
    border-radius: 3px;
    margin-bottom: 12px;

    &:focus {
      box-shadow: inset 0 0 0 2px $blue;

      &:hover {
        box-shadow: inset 0 0 0 2px $blue;
        background-color: white;
      }
    }
  }

  button {
    border: none;
    padding: 6px 12px;
    font-size: 0.875rem;
    color: #172b4d;
    margin: 10px 12px;
    border-radius: 3px;
    background-color: #091e420a;

    &:hover {
      background-color: #091e4214;

      @include activeHoverBlue();
    }
  }
}
