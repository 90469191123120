.board-header {
  padding: 8px 4px 7px 4px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;

  & > * {
    height: 32px;
  }

  .go-back {
    position: absolute;
    left: 5%;
    font-size: 22px;
    font-weight: 200;

    &:hover {
      cursor: pointer;
    }
  }

  .left {
    display: flex;
    margin-bottom: 5px;

    & > * {
      height: 32px;
    }

    .divider {
      margin-right: 12px;
    }

    input {
      color: white;
      font-size: 18px;
      font-weight: 700;
      line-height: 32px;
      padding: 0 4px;
      border-radius: 3px;
      max-width: auto;
      text-align: center;
      border: none;
      background-color: transparent;
      margin: 0 4px 4px 0;
      transition: all 0.15s;

      &:hover {
        cursor: pointer;
        background-color: rgba(255, 255, 255, 0.32);
      }

      &:focus {
        background-color: #fff;
        color: #172b4d;
        box-shadow: inset 0 0 0 2px $blue-500;
        outline: none;
        border-radius: 0;
      }

      &:focus:hover {
        cursor: text;
      }

      &.dark {
        background-color: transparent;

        &:focus {
          background-color: #fff;
        }
      }
    }

    .star-container {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 32px;
      width: 32px;
      line-height: 32px;
      border-radius: 3px;
      color: #fff;
      background-color: #ffffff3d;

      .yellow-star {
        color: #f2d600;
      }

      &:hover {
        cursor: pointer;
        color: #f2d600;

        & > * {
          transform: scale(1.2);
        }
      }
    }

    .board-members {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 11px;

      .member-img {
        margin-right: -3px;
        height: 28px;
        object-fit: cover;
        width: 28px;

        img {
          border-radius: 25em;
        }
      }
    }

    .btn-share {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 32px;
      padding: 6px 12px 6px 8px;

      &:hover {
        background-color: rgba(255, 255, 255, 0.9);
      }

      .person-icon {
        color: #42526e;
      }

      span {
        margin-left: 4px;
        font-size: 14px;
      }

      &.dark {
        color: white;
        background-color: #0079bf;

        .person-icon {
          color: white;
        }

        &:hover {
          background-color: #026aa7;
        }
      }
    }

    .dark {
      color: #172b4d;
      background-color: rgba(0, 0, 0, 0.1);

      &:hover {
        background-color: rgba(0, 0, 0, 0.2);
      }
    }
  }

  .right {
    margin: 0 4px 5px auto;
    transition: margin 0.12s ease-in;
    display: flex;
    display: flex;
    // gap: 4px;
    padding-inline-start: 5px;

    &.open {
      margin-right: 343px;
    }

    button {
      transition: 0.1s ease;
      background-color: #ffffff3d;
      border-radius: 3px;
      border: none;
      color: #fff;
      cursor: default;
      font-size: 14px;
      line-height: 32px;
      max-width: 400px;
      overflow: hidden;
      position: relative;
      text-decoration: none;
      text-overflow: ellipsis;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 9px;
      padding: 0;
      padding-right: 12px;
      padding-left: 9px;

      &:hover {
        cursor: pointer;
        background-color: #ffffff52;
      }
    }

    .dark {
      color: #172b4d;
      background-color: rgba(0, 0, 0, 0.1);

      &:hover {
        background-color: rgba(0, 0, 0, 0.2);
      }
    }

    .divider {
      margin-left: 8px;
      margin-right: 8px;
    }
  }

  .action-modal {
    .users {
      max-height: 400px;
      overflow: auto;

      &::-webkit-scrollbar {
        height: 8px;
        width: 8px;
      }

      &::-webkit-scrollbar-thumb {
        background-color: #bfc4ce;
        border-radius: 3px;
        height: 12px;
      }

      &::-webkit-scrollbar-track {
        background-color: #dadbe2;
        border-radius: 3px;
        height: 12px;
        margin-bottom: 8px;
        border-bottom: 10px solid transparent;
      }
    }

    margin-top: -4px;
    height: auto;
  }

  .divider {
    border-left: 1px solid #ffffff3d;
    height: 16px;
    margin: 8px 8px 12px 8px;

    &.dark {
      border-left: 1px solid rgba(0, 0, 0, 0.1);
    }
  }

  ////////////////////////////////////////////////////////////////////////////////////////

  // MEDIA QUERIES

  @media (max-width: 480px) {
    & .left {
      .board-members {
        display: none;
      }
    }
  }

  @media (min-width: 850px) {
    & {
      padding-left: 12px;
    }

    .left .star-container {
      display: flex;
    }

    .left .divider {
      display: block;
    }

    .right .divider {
      display: block;
    }

    .left .board-members {
      display: flex;
    }

    .left .btn-share {
      display: flex;
    }

    .right {
      gap: 0;

      .filter {
        display: flex;
      }
    }
  }
}
