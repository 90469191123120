.board-preview {
  width: 194px;
  height: 96px;
  color: #fff;
  font-size: 0.8547em;
  border-radius: 3px;
  position: relative;

  .board-preview-details {
    position: relative;

    h3 {
      z-index: 10;
      position: relative;
      padding: 8px;
    }

    .board-star {
      display: none;
      position: absolute;
      right: 5px;
      bottom: -58px;
      z-index: 10;

      &.starred {
        display: block;

        svg {
          color: #eed847;
        }
      }
    }
  }

  &:hover .board-star {
    display: block;
  }

  .board-hover {
    position: absolute;
    right: 0;
    left: 0;
    top: 0;
    bottom: 0;
    border-radius: 3px;
    background-color: rgb(0 0 0 / 27%);

    &:hover {
      background-color: rgb(0 0 0 / 37%);
    }
  }
}

// MEDIA QUERIES

@media (max-width: 700px) {
  .board-preview {
    width: 161px;
  }
}
