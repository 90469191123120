.action-modal {
  .member-select-list {
    padding: 8px 0;
    width: 321px;

    ul {
      position: relative;
      margin-inline-start: 0;
      padding-inline-start: 0;

      li {
        padding-inline-start: 42px;
        position: relative;
        height: 36px;

        label {
          position: absolute;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
          padding: 6px 6px 6px 42px;
        }

        &:hover {
          background-color: #e3e5e7;
        }

        .checkbox {
          left: 13px;
          top: 10px;
        }
      }
    }
  }
}
