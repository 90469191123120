.location-actions{
    padding-bottom: 12px;
    button {
        background-color: white;
        width: 100%;
        height: 32px;
        display: block;
        text-align: start;

        &:hover {
            background-color: #091e420a;
        }
    }
}