.edit-todo {
    margin-bottom: 3px;
    position: relative;
    left: 30px;

    .input {
        margin-left: 1px;
        margin-top: -6px;
        width: 512px;
        height: 56px;
        background: #091e420a;
        border-color: #091e4221;
        box-shadow: inset 0 0 0 1px #091e4221;
        margin-bottom: 5px;
        padding-top: 10px;

        &:focus {
            background-color: #091e420a;
        }
    }

    .options {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-left: 1px;
        gap: 12px;

        svg {
            font-size: 16px;
        }
    }

}