@font-face {
  font-family: CharlieDisplay-regular;
  src: url(../../fonts/Charlie/CharlieDisplay-Regular.ttf);
}

@font-face {
  font-family: CharlieDisplay-bold;
  src: url(../../fonts/Charlie/CharlieDisplay-Bold.ttf);
}

@font-face {
  font-family: CharlieDisplay-semibold;
  src: url(../../fonts/Charlie/CharlieDisplay-Semibold.ttf);
}

@font-face {
  font-family: CharlieText-regular;
  src: url(../../fonts/Charlie/CharlieText-Regular.ttf);
}