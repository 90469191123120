.location {
    padding: 4px 0 12px 0;

    input {
        height: 36px;
        width: 280px;
        margin: auto;
        padding-bottom: 10px;
        box-shadow: inset 0 0 0 2px #dfe1e6;
        background-color: #fafbfc;
        display: block;
        margin-bottom: 12px;

        &:hover {
            background-color: #ebecf0;
        }

        &:focus {
            background-color: #fff;
            box-shadow: inset 0 0 0 2px #0079bf;
            outline: 0;
        }
    }

    .location-list {
        // padding-top: 12px;
        font-size: 14px;


        section:first-child {
            background-color: #091e420a;
        }

        .location-preview {
        display: flex;

            justify-content: flex-start;
            align-items: center;
            height: 32px;    
            padding: 6px 12px;
            &:hover {
                cursor: pointer;
            }

            &:active {
                background-color: #e4f0f6;
            }
        }
    }
}