@mixin for-mobile-layout {
  @media (max-width: $break-narrow) {
    @content;
  }
}

@mixin for-narrow-layout {
  @media (min-width: $break-narrow) {
    @content;
  }
}

@mixin for-small-layout {
  @media (min-width: $break-small) {
    @content;
  }
}

@mixin for-normal-layout {
  @media (min-width: $break-normal) {
    @content;
  }
}

@mixin for-wide-layout {
  @media (min-width: $break-wide) {
    @content;
  }
}

@mixin inputFocus() {
  transition-property: background-color, border-color, box-shadow;
  transition-duration: 85ms;
  transition-timing-function: ease;
  background-color: #fafbfc;
  &:focus {
    background-color: white;
    box-shadow: inset 0 0 0 2px $blue-500;
  }
}

@mixin subHeader() {
  color: #5e6c84;
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  margin-top: 16px;
  margin-top: 12px;
}

@mixin flexAlign() {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin activeHoverBlue() {
  &:active:hover {
    background-color: #e4f0f5;
    color: rgb(0, 121, 192);
  }
}

@mixin smallScrollBar() {
  &::-webkit-scrollbar {
    height: 8px;
    width: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #bfc4ce;
    border-radius: 3px;
    height: 12px;
  }

  &::-webkit-scrollbar-track {
    background-color: #dadbe2;
    border-radius: 3px;
    height: 12px;
    margin-bottom: 8px;
    border-bottom: 10px solid transparent;
  }
}

@mixin checkboxSmall() {
  .checkbox-container {
    display: block;
    position: relative;
    padding-left: 33px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border-radius: 5px;

    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
    }

    .checkmark {
      position: absolute;
      top: -6px;
      left: 0;
      height: 13px;
      width: 13px;
      background-color: white;
      outline: 2px solid #dfe1e5;

      &:after {
        content: '';
        position: absolute;
        display: none;
      }
    }

    &:hover input ~ .checkmark {
      background-color: #ccc;
    }

    & input:checked ~ .checkmark {
      outline: none;
      background-color: #2196f3;
    }

    & input:checked ~ .checkmark:after {
      display: block;
    }

    & .checkmark:after {
      left: 4.5px;
      top: 0.6px;
      width: 2.5px;
      height: 8px;
      border: solid white;
      border-width: 0 2px 2px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }
  }
}
