.loader {
  font-size: 20px;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 20%;
  margin: auto;

  img {
    width: 50px;

    //! Lee's code to try fix the bug on getting photos - doesn't work everywhere
    position: absolute;
    left: 50%;
    top: 50%;
    translate: -50% -50%;
  }
}
