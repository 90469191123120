.task-preview {
  border-radius: 3px;
  background-color: white;
  box-shadow: 0 1px 0 #091e4240;
  margin: 0 4px;
  flex-shrink: 0;
  color: #172b4d;
  position: relative;

  &.dragging {
    rotate: 3deg;
    box-shadow: 0 1px 0 #091e4240;
  }

  &:hover {
    .quick-edit-icon {
      display: flex;
    }
  }

  &.full {
    .cover-color {
      min-height: 56px;

      &.img {
        .image-gradient {
          background: linear-gradient(transparent, white);
        }
      }
    }

    .task-body {
      position: absolute;
      bottom: 0;
      padding-bottom: 10px;
      font-size: 16px;
    }
  }

  .cover-color {
    min-height: 32px;
    background-size: 100% auto;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    overflow: hidden;
    display: flex;
    align-items: flex-end;

    p {
      padding-left: 8px;
      padding-right: 8px;
      padding-bottom: 10px;
      padding-top: 6px;
      font-size: 16px;
      font-weight: 500;
      line-height: 20px;
      color: #172b4d;
    }

    &.img {
      // margin-bottom: -5px;
      position: relative;
      z-index: 0;

      .image-gradient {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        border-radius: 3px;
        display: flex;
        align-items: flex-end;
      }
    }
  }

  .task-body {
    padding: 6px;
    padding-left: 8px;
    word-break: break-word;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    p {
      text-align: left;
    }
  }

  &:hover {
    cursor: pointer;
    background-color: #f4f4f8;
  }

  .add-item {
    margin: 0;
    padding: 8px;
    padding-top: 0;

    textarea {
      height: 65px;
      padding-top: 7px;
      padding-bottom: 42px;
      margin-bottom: 8px;
    }

    button {
      padding-left: 12px;
      padding-right: 12px;
    }
  }

  .add-task-button {
    height: auto;
    min-width: calc(100% - 40px);
    width: 256px;
    margin: auto;
    margin-left: 8px;
    color: #5e6c84;
    background-color: transparent;
    transition: none;
    margin-bottom: 8px;
    padding-left: 2px;

    span {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding: 6px 8px;
      gap: 5px;
    }

    &:hover {
      color: #172b4d;
      background-color: #091e4214;
    }
  }

  .quick-edit-icon {
    position: absolute;
    top: 2px;
    right: 2px;
    height: 28px;
    width: 28px;
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 3px;
    color: #42526e;
    background-color: #f4f5f7;
    opacity: 0.8;
    display: none;

    &:hover {
      opacity: 1;
    }
  }
}
