.activity-preview {
  display: grid;
  grid-template-columns: 32px 1fr;
  gap: 8px;
  color: #172b4d;

  .member-img {
    height: 32px;
    width: 32px;
    object-fit: cover;
    padding-bottom: px;

    img {
      width: 100%;
      border-radius: 25em;
    }
  }

  .activity-description {
    word-break: break-word;
    line-height: 20px;

    .username {
      font-weight: 700;
      margin-inline-end: 3px;
    }

    .task-name {
    }

    .time {
      font-weight: 400;
      font-size: 12px;
      color: #5e6c84;
    }

    .comment {
      margin-top: 3px;
      width: 271px;
      min-height: 36px;
      border-radius: 3px;
      box-shadow: 0 1px 2px -1px #091e4240, 0 0 0 1px #091e4214;
      background-color: white;
      padding: 8px 12px;
      margin-bottom: 6px;
    }
  }
}
