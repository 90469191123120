.users {
    padding: 0 12px 12px;
    .search-member {
      padding: 8px 12px;
      height: 36px;
      width: 100%;
      margin-bottom: 12px;
      @include inputFocus;
  
      &:hover {
        background-color: #ebecf0;
      }
    }
  
    .sub-header {
      font-size: 12px;
      font-weight: 600;
      line-height: 16px;
      color: #5e6c84;
      margin-bottom: 8px;
    }
  
    .members-list {
      padding-inline-start: 0;
      margin: 0;
    }
    .member-container {
      height: 38px;
      line-height: 32px;
      border-radius: 3px;
      padding: 6px 4px;
      margin-bottom: 2px;
      overflow: hidden;
      display: flex;
      align-items: center;
      flex-direction: row;
      margin: 0 0 2px;
  
      &:hover {
        background-color: #091e420a;
        cursor: pointer;
      }
  
      &:active:hover {
        background-color: #e4f0f5;
        color: rgb(0, 121, 192);
      }
  
      .member-img {
        height: 32px;
        object-fit: cover;
        width: 32px;
        margin-inline-end: 8px;
  
        img {
          border-radius: 25em;
        }
      }
  
      .member-fullname {
        font-size: 14px;
        color: #172b4d;
        line-height: 32px;
      }
  
      .checked-icon {
        padding: 8px;
        display: flex;
        justify-content: flex-end;
        color: #42526e;
        flex: 1;
      }
    }
  }
  