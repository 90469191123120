.members-filter {
  font-size: 14px;
  margin-bottom: 15px;

  h3 {
    margin-bottom: 15px;
  }


  &.filter {
    margin-bottom: 15px;
    ul {
      margin: 0;

      li {
        label {
          padding: 2px 0 15px 8px;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          gap: 10px;

          p {
            letter-spacing: 0.1px;
          }

          .member-search {
            font-size: 14px;
            margin-top: -8px;

            .search-member {
              padding: 12px 12px;
              padding-bottom: 16px;
            }
          }
        }
      }

      .select-member-label {
        padding-bottom: 0;
      }
    }
  }
}