.task-members-overview {
  margin: 0 16px 0 0;
  .title {
    font-size: 12px;
    line-height: 20px;
    font-weight: 600;
    color: #5e6c84;
    margin: 0 8px 4px 0;
    width: 100%;
  }

  .members-container {
    display: flex;

    .member-img {
      height: 32px;
      object-fit: cover;
      width: 32px;
      margin: 0 4px 4px 0;

      img {
        border-radius: 25em;
      }
    }

    .btn-add-member {
      font-size: 17px;
      height: 32px;
      width: 32px;
      border-radius: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #42526e;
      &:hover {
        background-color: #e1e4e9;
      }

      @include activeHoverBlue();
    }
  }
}
