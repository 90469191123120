.dates {
  display: flex;
  flex-direction: column;

  .action-btn {
    padding: 6px 12px;
    margin: 0 12px 8px;
    font-size: 14px;
    border-radius: 3px;
  }

  .save-btn {
    color: white;
    background-color: #0079bf;

    &:hover {
      background-color: #026aa7;
    }
  }

  .remove-btn {
    color: #172b4d;
    background-color: rgba(9, 30, 66, 0.04);

    &:hover {
      background-color: rgba(9, 30, 66, 0.08);
    }
  }
}
