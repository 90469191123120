.add-todo {
  margin-top: 16px;
  margin-left: 40px;

  .input {
    height: 37px;
    margin-bottom: 8px;
    width: 290px;
  }

  .options {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 4px;

    button:last-of-type {
      background-color: transparent;

      &:hover {
        background-color: #091e4214;
      }
    }
  }
}

// MEDIA QUERIES

@media (min-width: 480px) {
  .add-todo {
    .input {
      width: 376px;
    }
  }
}

@media (min-width: 560px) {
  .add-todo {
    .input {
      width: 456px;
    }
  }
}

@include for-narrow-layout {
  .add-todo {
    .input {
      width: 512px;
    }
  }
}