.header-boards {
    color: #5E6C84;
    margin-top: 17px;
    // width: 304px;    
    max-height: 500px;
    overflow-y: auto;

    p {
        font-size: 12px;
        line-height: 16px;
        padding-left: 11px;
        margin-bottom: 12px;
        font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Noto Sans', 'Ubuntu', 'Droid Sans', 'Helvetica Neue', sans-serif;
    }

    &::-webkit-scrollbar {
        height: 8px;
        width: 8px;
      }
    
      &::-webkit-scrollbar-thumb {
        background-color: #c6cbd4;
        border-radius: 3px;
        height: 12px;
      }
    
      &::-webkit-scrollbar-track {
        background-color: #e2e4e9;
        border-radius: 3px;
        margin-top: -6px;
        margin-bottom: 2px;
        height: 12px;
        border-bottom: 10px solid transparent;
      }
}