.chat {
    border-radius: 3px;
    height: 500px;
    width: 300px;
    position: absolute;
    right: 10px;
    bottom: 28px;
    background-color: white;
    display: flex;
    flex-direction: column;
    border: 1px solid #091e4221;

    header {
        color: #5e6c84;
        height: 40px;
        font-size: 1rem;
        text-align: center;
        line-height: 40px;
        margin: 0 12px 8px;
        border-bottom: 1px solid #091e4221;
        margin-bottom: 0;
        position: relative;

        p {
            display: inline-block;
        }

        span {
            position: absolute;
            font-size: 1.2rem;
            right: 9px;
            top: 2px;
            color: #6b778c;

            &:hover {
                cursor: pointer;
                color: #172b4d;
            }
        }

        svg {
            position: absolute;
            right: 0px;
            font-size: 20px;
            height: 40px;
            font-weight: 200;

            &:hover {
                cursor: pointer;
            }
        }
    }

    .user {
        display: flex;
        gap: 10px;
        padding: 5px 10px;
        border-top: 1px solid #091e4221;


        .img-container {
            object-fit: cover;
            height: 32px;
            width: 32px;

            img {
                width: 100%;
                height: 100%;
                border-radius: 50%;
            }
        }

        form {
            flex-grow: 1;
            position: relative;

            input {
                width: 100%;
                height: 32px;
                border-radius: 8px;
                border: 1px solid #091e4221;
                padding: 0 10px;

                &:focus {
                    outline: none;
                }
            }
        }
    }

}