.task-sidebar {
  display: flex;
  flex-direction: column;
  padding-block-end: 8px;

  h3.sidebar-title {
    color: #5e6c84;
    font-size: 12px;
  }
  .btn-container {
    height: 200px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 5px;

    & > * {
      width: 168px;
      margin-top: 9px;
    }

    .btn-sidebar {
      padding: 4px 9px;
      font-size: 14px;
      background-color: $background-gray;
      border: none;
      text-align: left;
      color: #1c2a4b;
      display: flex;
      align-items: center;

      &:hover {
        background-color: #e1e4e9;
      }

      @include activeHoverBlue();

      .icon {
        padding-right: 10px;
        font-size: 24px;
      }
    }
  }
}

// MEDIA QUERIES

@include for-narrow-layout {
  .task-sidebar {
    .btn-container {
      height: 130px;
      gap: 0;
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
    }
  }
}

@include for-small-layout {
  .task-sidebar {
    padding-inline-start: 8px;
    padding-block-end: 0;

    .btn-container {
      gap: 0;
      display: block;
      height: unset;
    }
  }
}
