.message-preview {
    display: flex;
    gap: 8px;

    .img-container {
        object-fit: cover;
        height: 36px;
        width: 36px;
        align-self: flex-end;

        img {
            width: 100%;
            height: 100%;
            border-radius: 50%;
        }
    }

    .body {
        flex-grow: 1;
        border-radius: 10px;
        border-bottom-left-radius: 0;
        padding: 4px 12px;
        padding-top: 2px;
        padding-bottom: 6px;
        background-color: #ebecf0;
        box-shadow: 0 1px 0 rgb(9 30 66 / 25%);
        word-wrap: break-word;
        max-width: 228px;

        .name {
            font-weight: 600;
            font-size: 12px;
            margin-bottom: -2px;
        }

        .msg {
            font-size: 16px;
        }
    }

}