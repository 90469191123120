.color-pallet {
  display: flex;
  gap: 7.5px;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 10px;

  .color {
    border-radius: 3px;
    cursor: pointer;
    width: 48.8px;
    height: 28px;
  }

  .selected-container {
    width: 48.8px;
    height: 28px;
    border-radius: 3px;
    outline: 2px #0079bf solid;
    @include flexAlign;

    .selected-color {
      border-radius: none;
      width: 44.5px;
      height: 24.5px;
    }
  }
}
