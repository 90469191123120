.message-list {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 10px;
  background-color: white;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  max-height: 468px;
  overflow-y: auto;

  &::-webkit-scrollbar {
    height: 8px;
    width: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #a1a7b1;
    border-radius: 3px;
    height: 12px;
  }

  &::-webkit-scrollbar-track {
    background-color: #c7c9d0;
    border-radius: 3px;
    margin-top: 3px;
    margin-bottom: 2px;
    height: 12px;
    border-bottom: 10px solid transparent;
  }
}