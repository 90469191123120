.check-list {
  margin-top: 13px;
  padding: 0 12px 12px;
  font-size: 12px;
  width: 304px;

  label {
    color: #5e6c84;
    font-size: 0.75rem;
    font-weight: 700;
    display: block;
    margin-bottom: 4px;
    margin-top: 12px;
  }

  .input-container {

    input {
      height: 36px;
      margin-bottom: 22px;
    }

    &:hover input {
      background-color: #ebecf0;
      box-shadow: inset 0 0 0 2px #dfe1e6;
    }

    input:focus {
      background-color: white;
      box-shadow: inset 0 0 0 2px $blue-500;

    }
  }

  button {
    margin: 0;
    height: 32px;
    padding: 6px 24px;
  }
}