.app-header {
  font-family: CharlieText-regular;
  background-color: #026aa7;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: background-color 0.5s ease 0s, box-shadow 0.5s ease 0s;
  transition-property: background-color, box-shadow;
  transition-duration: 0.5s, 0.5s;
  position: relative;
  width: 100%;
  color: white;
  min-height: 44px;
  border-bottom: 1px solid hsla(0, 0%, 100%, 0.16);
  transition: background-color 300ms;

  &.fixed {
    position: fixed;
  }

  .left {
    display: flex;

    .main-logo {
      border-radius: 3px;
      font-family: CharlieDisplay-bold;
      display: flex;
      align-items: center;
      padding: 0 6px;
      margin-left: 6px;

      &:hover {
        background-color: rgba(255, 255, 255, 0.3);
      }

      h1 {
        margin-left: 6px;
        font-size: 1.4rem;
        line-height: 30px;
      }

      img {
        margin-right: 7px;
        width: 22px;
      }

      &.dark {
        color: #172b4d;

        &:hover {
          background-color: rgba(0, 0, 0, 0.2);
        }
      }
    }

    .boards {
      height: 30px;
      border-radius: 3px;
      font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Noto Sans', 'Ubuntu', 'Droid Sans', 'Helvetica Neue', sans-serif;
      display: flex;
      align-items: center;
      padding: 8px 32px 6px 12px;
      margin-left: 6px;
      font-size: 14px;
      position: relative;

      .svg-container {
        position: absolute;
        right: 8px;
        top: 6px;
        font-size: 20px;
      }

      &:hover {
        background-color: rgba(255, 255, 255, 0.3);
        cursor: pointer;
      }

      &.dark {
        color: #172b4d;

        &:hover {
          background-color: rgba(0, 0, 0, 0.2);
        }
      }
    }
  }



  .home-nav {
    font-size: 1.25em;
    display: flex;
    align-items: center;
    gap: 4px;

    &.none {
      display: none;
    }

    &>* {
      padding: 0px 10px;
      height: 44px;
      display: flex;
      align-items: center;
      font-size: 1rem;
    }

    .login {
      color: #172a4d;
    }

    .signup {
      background-color: $blue-main;
      color: white;

      &:hover {
        background-color: #0747a6;
      }
    }
  }

  &.home-header {
    background-color: #fff;

    .home-nav>* {
      height: 60px;
    }

    .main-logo {
      font-size: 22px;
      margin-left: 10px;
      color: #095ed8;

      h1 {
        color: black;
        font-size: 30px;
        margin-left: 11px;
      }
    }

    &:hover {
      box-shadow: rgb(9 30 66 / 15%) 0px 0.5rem 1rem 0px;
    }
  }

  &.login-header {
    display: none;
  }

  .user-img {
    height: 32px;
    object-fit: cover;
    width: 32px;
    margin-inline-end: 8px;
    cursor: pointer;

    img {
      border-radius: 25em;
    }
  }
}

// MEDIA QUERIES

//////////////////////////////////////////// 640px ////////////////////////////////////////////
@include for-narrow-layout {
  .app-header {
    .home-nav {
      &>* {
        font-size: 1.2rem;
        padding: 0px 22px;
      }
    }
  }
}

//////////////////////////////////////////// 780px ////////////////////////////////////////////
@include for-small-layout {}

//////////////////////////////////////////// 980px ////////////////////////////////////////////
@include for-normal-layout {}

@include for-wide-layout {}