.action-modal {
  position: fixed;
  min-width: 304px;
  background-color: #fff;
  border-radius: 3px;
  z-index: 100;
  box-shadow: 0 8px 16px -4px #091e4240, 0 0 0 1px #091e4214;

  .edit-go-back {
    position: absolute;
    left: 10px;
    top: 12px;
    font-size: 17px;
    cursor: pointer;
  }

  input {
    border: none;
    box-shadow: inset 0 0 0 2px #dfe1e6;
    padding: 8px 12px;
    font-size: 0.875rem;
    width: 100%;
    color: #172b4d;
    outline: none;
    border-radius: 3px;

    &:focus {
      box-shadow: inset 0 0 0 2px rgb(23, 43, 77);

      &:hover {
        outline-color: inset 0 0 0 2px rgb(23, 43, 77);
        background-color: white;
      }
    }
  }

  .title-container {
    color: #5e6c84;
    height: 40px;
    font-size: 0.875rem;
    text-align: center;
    line-height: 40px;
    margin: 0 12px 8px;
    border-bottom: 1px solid #091e4221;

    p {
      display: inline-block;
    }

    span {
      position: absolute;
      font-size: 1.2rem;
      right: 9px;
      top: 2px;
      color: #6b778c;

      &:hover {
        cursor: pointer;
        color: #172b4d;
      }
    }
  }
}
