.quick-edit {
  position: fixed;
  width: 256px;
  z-index: 20;
  height: 160px;

  textarea {
    min-height: 96px;
    padding: 6px 8px 2px;
    background-color: white;
    width: 100%;
    margin-bottom: 11px;
    border-radius: 3px;
  }

  button {
    padding: 8px 24px;
  }
}

.screen-edit {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow-y: auto;
  z-index: 15;
  background-color: #000000a3;
  cursor: pointer;
}
