.screen {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow-y: auto;
  z-index: 100;
}

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  background-color: #000000a3;
}

.task-details-container {
  display: inline-block;
  position: relative;
  transform: translate(-50%, 0);
  left: 50%;
}

.task-details {
  position: relative;
  margin-top: 48px;
  margin-bottom: 48px;
  background-color: #f4f5f7;
  z-index: 100;
  border-radius: 2px;
  max-width: 370px;
  min-height: 600px;
  display: flex;
  overflow-y: auto;
  flex-direction: column;

  &:hover {
    cursor: auto;
  }

  .cover-color {
    height: 116px;
    display: flex;
    justify-content: center;
    align-items: center;

    &.img {
      height: 160px;
    }

    img {
      height: 100%;
      width: auto;
    }
  }

  .close-task-details {
    position: absolute;
    height: 40px;
    width: 40px;
    right: 3px;
    top: 2px;
    background-color: transparent;
    border: none;
    color: $light-gray;
    font-size: 27px;
    padding: 6px 6px 2px;
    margin: 4px;
    border-radius: 50%;
    transition: all 0.3s;

    &:hover {
      background-color: #e1e4e9;
    }
  }

  .on-cover {
    background-color: #00000014;
    &:hover {
      background-color: #00000029;
    }
  }

  .task-body {
    height: 100%;
    display: flex;
    flex-direction: column-reverse;
    padding: 0 16px 8px;

    h3 {
      color: #172a4d;
      font-size: 16px;
      line-height: 20px;
      font-weight: 600;

      & + svg {
        position: relative;
        right: 33px;
        bottom: 17px;
        font-size: 18px;
      }
    }

    .task-content {
      flex-grow: 3;
      margin-inline-end: 8px;

      .activities {
        .header {
          color: #172a4d;
          font-size: 17px;
          line-height: 20px;
          font-weight: 600;
          padding: 0;
          font-size: 16px;
          margin: 10px 7px;
          margin-bottom: 4px;
          gap: 17px;

          &:hover {
            background-color: transparent;
          }

          svg {
            position: relative;
            font-size: 16px;
          }
        }

        .activity-list {
          font-size: 14px;
          margin-top: 8px;
          padding-left: 0;
          gap: 16px;
          max-width: 552px;

          .activity-preview {
            gap: 8px;

            .comment {
              width: 510px;
            }
          }
        }
      }
    }
  }
}

// MEDIA QUERIES

@media (min-width: 480px) {
  .task-details {
    max-width: 460px;
  }
}

@media (min-width: 560px) {
  .task-details {
    max-width: 540px;
  }
}

@include for-narrow-layout {
  .task-details {
    max-width: 600px;
  }
}

@include for-small-layout {
  .task-details {
    max-width: 768px;

    .task-body {
      flex-direction: row;
    }
  }
}
