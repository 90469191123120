.group-list {
  padding: 0 8px 0 14px;
  display: flex;
  flex-wrap: nowrap;
  // height: calc(100% - 52px);
  max-height: 100%;
  flex-grow: 1;
  align-items: flex-start;
  // gap: 8px;
  line-height: 20px;
  overflow-x: auto;
  border-bottom: 8px transparent solid;

  .add-task-button {
    height: 40px;
    border: none;
    min-width: 272px;
    text-align: start;
    padding: 0px 8px 0px 6px;
    transition: background 85ms ease-in, opacity 40ms ease-in,
      border-color 85ms ease-in;
    font-size: 14px;
    width: 272px;
    border-radius: 3px;
    border: none;
    background-color: #ffffff3d;
    color: white;

    span {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding: 6px 8px;
      gap: 6px;
    }

    &:hover {
      background-color: #ffffff52;
    }
  }

  &::-webkit-scrollbar {
    height: 12px;
    width: 12px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: hsla(0, 14%, 91%, 0.456);
    border-radius: 3px;
    height: 12px;
  }

  &::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0.2);
    border-radius: 3px;
    margin-left: 48px;
    margin-right: 48px;
    height: 12px;
    border-bottom: 10px solid transparent;
  }

  .dark {
    color: #172b4d;
    background-color: rgba(0, 0, 0, 0.1);

    &:hover {
      background-color: rgba(0, 0, 0, 0.2);
    }
  }

  .chat-btn {
    width: 44px;
    height: 44px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: 12px;
    bottom: 28px;
    background-color: rgba(0, 0, 0, 0.6);
    border-radius: 50%;
    color: white;
    font-size: 20px;

    &:hover {
      cursor: pointer;
      background-color: rgba(0, 0, 0, 0.8);
    }

    &:active {
      background-color: black;
    }
  }
}

//MEDIA QUERY

@media (min-width: 750px) {
  .group-list {
    padding: 0 22px;
  }
}
