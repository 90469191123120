.board-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  // display: grid;
  // grid-template-columns: repeat(auto-fit, minmax(194px, 194px));
  // justify-content: center;
  gap: 17px;
  padding: 0 0 11px;

  .new-board {
    width: 194px;
    height: 96px;
    background-color: #091e420a;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    border-radius: 3px;
    color: $blue-common;

    &:hover {
      cursor: pointer;
      background-color: #091e4214;
    }
  }
}

// MEDIA QUERIES

@media (max-width: 700px) {
  .board-list {
    gap: 8px;
    .new-board {
      width: 161px;
    }
  }
}
