.checklist-preview {
  margin-bottom: 40px;
  width: 330px;
  height: auto;

  button {
    padding: 6px 12px;
    margin-top: 0px;
    background-color: $background-gray;
    border: none;
    text-align: left;
    color: #1c2a4b;
    display: flex;
    align-items: center;
    border-radius: 3px;
    line-height: 20px;

    &:hover {
      background-color: #e1e4e9;
    }

    &:active:hover {
      background-color: #e4f0f5;
      color: rgb(0, 121, 192);
    }
  }

  .checklist-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    height: auto;

    .left {
      padding-left: 7px;
      gap: 15px;
      justify-content: center;
      height: 100%;

      h3 {
        font-size: 16px;

        &:hover {
          cursor: pointer;
        }
      }

      svg {
        bottom: 33px;
        font-size: 18px;
        font-weight: 600;
      }
    }

    .right {
      margin-right: 2px;
    }

    &>* {
      display: flex;
      align-items: center;
    }
  }

  .checklist-status {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-inline-start: 8px;

    p {
      padding-left: 1px;
      font-size: 10px;
    }

    .completion-bar {
      width: 290px;
      height: 8px;
      background-color: #091e4214;
      border-radius: 4px;
      overflow: hidden;

      .percentage-bar {
        background-color: #5ba4cf;
        height: 100%;
        width: 0%;
        transition-delay: 0.2s;
        transition-duration: 0.14s;
        transition-property: width;
        transition-timing-function: ease-in;

        &.done {
          background-color: #61bd4f;
        }
      }
    }
  }

  .add-todo-button {
    margin-top: 20px;
    margin-left: 40px;
  }
}

// MEDIA QUERIES

@media (min-width: 480px) {
  .checklist-preview {
    width: 416px;

    .checklist-status .completion-bar {
      width: 376px;
    }
  }

}

@media (min-width: 560px) {
  .checklist-preview {
    width: 496px;

    .checklist-status .completion-bar {
      width: 456px;
    }
  }


}

@include for-narrow-layout {
  .checklist-preview {
    width: 552px;

    .checklist-status .completion-bar {
      width: 512px;
    }
  }
}