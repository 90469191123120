.btn {
  border: none;
  padding: 6px 12px;
  font-size: 0.875rem;
  color: #172b4d;
  border-radius: 3px;
  background-color: #091e420a;

  &:hover {
    background-color: #091e4214;

    &:active {
      background-color: #e4f0f6;
      color: $blue-main;
    }
  }

  &.blue {
    color: white;
    background-color: #0079bf;

    &:hover {
      background-color: #026aa7;
    }
  }
}

.input {
  border: none;
  box-shadow: inset 0 0 0 2px #dfe1e6;
  padding: 8px 12px;
  font-size: 0.875rem;
  width: 100%;
  color: #172b4d;
  outline: none;
  border-radius: 3px;
  margin-bottom: 12px;

  &:focus {
    box-shadow: inset 0 0 0 2px #0079bf;

    &:hover {
      outline-color: inset 0 0 0 2px #0079bf;
      background-color: white;
    }
  }
}