.task-header {
  height: 60px;
  margin-left: 48px;
  margin-right: 40px;
  margin-top: 15px;
  margin-bottom: 10px;

  textarea {
    height: 32px;
    font-size: 20px;
    font-weight: 600;
    line-height: 24px;
    width: 97%;
    padding: 4px 8px;
    transition: box-shadow 0.2s;
    border-radius: 3px;

    &:focus {
      background-color: white;
      box-shadow: inset 0 0 0 2px $blue-500;
    }
  }

  .header-icon {
    position: relative;
    right: 26px;
    bottom: 46px;
    font-size: 18px;
  }

  .sub-title {
    color: #616d82;
    font-size: 14px;
    margin-inline-start: 8px;

    .group-title {
      text-decoration: underline;
    }
  }
}
