.dynamic-textarea {
  textarea {
    height: 100%;
    font-size: 20px;
    font-weight: 600;
    width: 97%;
    padding: 7px 0 7px 6px;
    transition: box-shadow 0.2s;
    border-radius: 3px;
    flex-grow: 3;
    &:focus {
      background-color: white;
      box-shadow: inset 0 0 0 2px $blue-500;
    }
  }
}
