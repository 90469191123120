.task-list {
  padding: 5px 0px 2px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin: 0 4px;
  margin-bottom: 7px;
  // max-height: calc(100vh - 44px - 52px - 108px);
  overflow-y: auto;
  position: relative;
  // & > *:not(:first-child) {
  //   margin-top: 8px;
  // }

  &.full {
    max-height: calc(100vh - 44px - 52px - 72px);
  }

  .add-item {
    padding: 0;
    margin: 0 4px;
    min-width: 0;
    width: auto;

    form {
      width: 100%;
    }

    textarea {
      height: 65px;
      padding-top: 7px;
      padding-bottom: 42px;
      margin-bottom: 8px;
    }

    button {
      padding-left: 12px;
      padding-right: 12px;
    }
  }

  &::-webkit-scrollbar {
    height: 8px;
    width: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #bfc4ce;
    border-radius: 3px;
    height: 12px;
  }

  &::-webkit-scrollbar-track {
    background-color: #dadbe2;
    border-radius: 3px;
    height: 12px;
    border-bottom: 10px solid transparent;
  }
}

.wrapper {
  overflow-x: visible;
  width: 272px;
  min-width: 272px;
}
