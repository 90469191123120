.group-preview {
  display: flex;
  flex-direction: column;
  background-color: #ebecf0;
  min-width: 272px;
  width: 272px;
  border-radius: 3px;
  font-size: 0.875rem;
  max-height: 98%;
  margin-inline-end: 8px;

  &.dragging {
    rotate: 3deg;
  }

  .group-title {
    font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Noto Sans,
      Ubuntu, Droid Sans, Helvetica Neue, sans-serif;
    color: #172b4d;
    padding-top: 10px;
    padding-left: 10px;
    padding-right: 3px;
    font-weight: 800;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 34px;

    button {
      border: none;
      border-radius: 3px;
      width: 32px;
      height: 32px;
      font-size: 18px;
      background-color: transparent;
      display: flex;
      justify-content: center;
      align-items: center;
      line-height: 20px;
      margin-bottom: 6px;
      display: flex;
      align-items: flex-start;
      color: #6d788a;

      &:hover {
        background-color: #091e4214;
      }
    }

    &:hover {
      cursor: pointer;
    }
  }

  .add-task-button {
    height: auto;
    min-width: calc(100% - 40px);
    width: 256px;
    margin: auto;
    margin-left: 8px;
    color: #5e6c84;
    background-color: transparent;
    transition: none;
    margin-bottom: 8px;
    padding-left: 4px;

    span {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding: 6px 8px;
      gap: 5px;

      svg {
        font-size: 12px;
        margin-right: 1px;
      }
    }

    &:hover {
      color: #172b4d;
      background-color: #091e4214;
    }
  }
}
