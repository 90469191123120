.board-side-menu {
  position: fixed;
  background-color: #f4f5f7;
  color: #172b4d;
  width: 339px;
  top: 44px;
  right: 0;
  height: calc(100vh - 44px);
  transition-duration: 0.1s;
  transition-property: transform, width;
  transition-timing-function: ease-in;
  font-size: 14px;
  z-index: 20;
  box-shadow: 0 12px 24px -6px #091e4240, 0 0 0 1px #091e4214;
  transform: translateX(100%);
  transition-timing-function: ease-in;
  transition-duration: 0.1s;
  overflow: hidden;

  &.open {
    transform: translateX(0%);
  }

  .header {
    position: relative;
    height: 48px;
    flex: 1;
    line-height: 20px;
    text-align: center;
    text-overflow: ellipsis;
    transition: margin 0.12s ease-in;
    white-space: nowrap;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    font-weight: 600;
    padding-left: 6px;

    a {
      text-decoration: underline;
    }

    .svg-holder {
      position: absolute;
      right: 4%;
      bottom: 45%;
      transform: translate(0, 50%);

      &:hover {
        cursor: pointer;
      }
    }
  }

  .divider-side {
    height: 1px;
    background-color: #091e4221;
    width: 321px;
    margin: auto;
  }

  .board-menu-content-frame {
    padding: 16px 6px 8px 12px;

    button {
      padding-left: 12px;
      background-color: transparent;
      font-weight: 600;
      line-height: 20px;
      border-radius: 3px;
      color: inherit;
      width: 100%;
      text-align: start;
      height: 32px;
      margin-bottom: 8px;

      &:hover {
        background-color: #091e4214;
      }
    }
  }

  .display {
    height: 96px;
    background-color: blue;
    border-radius: 8px;
    width: 100%;
    background-size: cover;
  }

  .main-layout {
    padding-left: 16px;
    padding-top: 12px;

    .display-grid {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 8px;
    }
  }

  .hover:hover {
    opacity: 0.7;
    cursor: pointer;
  }

  .hover-darker:hover {
    opacity: 0.8;
    cursor: pointer;
  }
}
