.task-labels-overview {
  .title {
    font-size: 12px;
    line-height: 20px;
    font-weight: 600;
    color: #5e6c84;
    margin: 0 8px 4px 0;
    width: 100%;
  }

  .labels-container {
    display: flex;
    gap: 5px;
    flex-wrap: wrap;

    .color-circle {
      height: 16px;
      width: 16px;
      border-radius: 50%;
      margin-inline-end: 7px;
    }

    .btn-label {
      height: 32px;
      font-size: 14px;
      font-weight: 400;
      display: flex;
      align-items: center;
      padding-inline-start: 7px;
      padding-inline-end: 12px;
    }

    .btn-add-label {
      font-size: 17px;
      height: 32px;
      width: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #42526e;
      &:hover {
        background-color: #e1e4e9;
      }

      @include activeHoverBlue();
    }
  }
}
