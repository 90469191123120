.board {
  // height: calc(100vh - 44px);
  height: calc(100vh - 44px);
  // background-image: url('https://images.unsplash.com/photo-1660551772352-0855c10356b1?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80');
  background-repeat: no-repeat;
  background-size: cover;
  width: 100vw;
  display: flex;
  flex-direction: column;
}
