.action-modal {
  .label-select-list {
    width: 321px;
    margin-bottom: 8px;
    max-height: 240px;
    overflow-y: auto;

    ul {
      position: relative;
      margin-inline-start: 0;
      padding-inline-start: 0;

      li {
        // padding-inline-start: 42px;
        position: relative;

        label {
          padding: 6px 12px 6px 42px;

          .label-color {
            width: 100%;
          }
        }

        &:hover {
          background-color: #e3e5e7;
        }
        &:active {
        }

        .checkbox {
          top: 6px;
          left: 12px;
        }
      }
    }
  }
}
