.quick-edit-buttons {
  position: relative;
  left: 260px;
  bottom: 144.5px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: flex-start;
  button {
    display: block;
    margin: 0 0 4px 8px;
    padding: 6px 12px 6px 8px;
    transition: transform 85ms ease-in;
    height: 32px;
    padding: 6px 12px 6px 8px;
    font-size: 15px;
    color: #fff;
    background-color: #0009;

    &:hover {
      background-color: #000c;
      transform: translateX(5px);
    }

    svg {
      margin-inline-end: 8px;
    }
  }
}
