.workspace {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin: auto;
  padding: 40px 0;
  padding-top: 47px;
  min-height: calc(100vh - 44px);
  max-width: 330px;

  .title {
    display: flex;
    align-items: center;
    color: #172b4d;
    font-size: 0.8547rem;
    padding: 0 0 11px;
    margin-inline-start: 2px;

    .title-icon {
      color: #42526e;
      font-size: 1.5rem;
      margin-inline-end: 12px;
    }
  }

  .starred-boards {
    margin-bottom: 45px;
  }
}

// MEDIA QUERIES

@media (max-width: 330px) {
  .workspace {
    max-width: 180px;
  }
}

@media (min-width: 525px) {
  .workspace {
    max-width: 499px;
  }
}

@media (min-width: 600px) {
  .workspace {
    max-width: 499px;
  }
}

@media (min-width: 701px) {
  .workspace {
    max-width: 616px;
  }
}

@media (min-width: 860px) {
  .workspace {
    max-width: 827px;
  }
}

@media (min-width: 1118px) {
  .workspace {
    max-width: 1038px;
  }
}
