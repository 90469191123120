.task-dates-overview {
  h3.task-dates-overview-title {
    font-size: 12px;
    color: #5e6c84;
    margin-block-end: 4px;
  }

  .due-date-badge {
    display: flex;
    align-items: center;
    flex-direction: row;
    margin: 0 8px 8px 0;

    @include checkboxSmall();

    .checkbox {
      height: 14px;
      width: 14px;
      margin: auto 4px auto 0;
      cursor: pointer;
      border-color: #dfe1e5;
      border-radius: 3px;
      &:checked {
        accent-color: #5ba4cf;
      }
    }

    .due-date-container {
      button {
        display: flex;
        align-items: center;
        font-size: 14px;
        padding: 6px 12px;
        background-color: rgba(9, 30, 66, 0.04);
        border-radius: 3px;
        color: #172b4d;

        &:hover {
          background-color: rgba(9, 30, 66, 0.08);
        }

        &:active:hover {
          background-color: #e4f0f5;
          color: rgb(0, 121, 192);
        }

        svg {
          font-size: 20px;
          margin-left: 4px;
        }

        .overdue {
          background-color: #ec9488;
          color: #fff;
        }

        .soon {
          background-color: #f2d600;
          color: #172b4d;
        }

        .completed {
          background-color: #61bd4f;
          color: #fff;
        }

        .due-sticker {
          border-radius: 2px;
          font-size: 12px;
          line-height: 16px;
          padding: 0 4px;
          margin: auto 0 auto 8px;
        }
      }
    }
  }
}
