.create-board {
  display: flex;
  justify-content: center;
  flex-direction: column;
  .new-board-container {
    width: 200px;
    height: 120px;
    background-position: center center;
    background-size: cover;
    border-radius: 3px;
    box-shadow: 0 7px 15px rgb(0 0 0 / 15%);
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 8px;
    align-self: center;

    img {
      width: 186px;
      height: 103px;
    }
  }

  .background-picker-container {
    padding: 12px;

    p {
      font-weight: 700;
      font-size: 12px;
      color: #5e6c84;
      margin: 8px 0px 4px;
    }

    .background-picker {
      .background-images {
        display: flex;
        justify-content: space-between;
        margin: 0;
        padding: 0;
        padding-bottom: 8px;

        .image-button {
          width: 64px;
          height: 40px;

          button {
            height: 100%;
            width: 100%;
            position: relative;
            background-position: center center;
            background-size: cover;

            .selected {
              position: absolute;
              left: 0;
              top: 0;
              bottom: 0;
              right: 0;
              display: flex;
              justify-content: center;
              align-items: center;
              color: white;
              font-size: 13px;
              border-radius: 3px;
              background-color: rgba(0, 0, 0, 0.4);
            }

            .hover {
              position: absolute;
              left: 0;
              top: 0;
              bottom: 0;
              right: 0;
              &:hover {
                background-color: rgba(9, 30, 66, 15%);
              }
            }
          }
        }
      }

      .background-colors {
        display: flex;
        justify-content: space-between;
        margin: 0;
        padding: 0;

        .color-button {
          width: 40px;
          height: 32px;

          button {
            height: 100%;
            width: 100%;
            position: relative;

            .selected {
              position: absolute;
              left: 0;
              top: 0;
              bottom: 0;
              right: 0;
              display: flex;
              justify-content: center;
              align-items: center;
              color: white;
              font-size: 13px;
              border-radius: 3px;
              background-color: rgba(0, 0, 0, 0.4);
            }

            .hover {
              position: absolute;
              left: 0;
              top: 0;
              bottom: 0;
              right: 0;
              &:hover {
                background-color: rgba(9, 30, 66, 15%);
              }
            }
          }
        }
      }
    }

    form {
      margin-top: 14px;

      .input-container {
        input {
          &:focus {
            box-shadow: inset 0 0 0 2px $blue-500;

            &:hover {
              box-shadow: inset 0 0 0 2px $blue-500;
              background-color: white;
            }
          }
        }

        &:hover input {
          background-color: #ebecf0;
          box-shadow: inset 0 0 0 2px #dfe1e6;
        }
      }

      .create-btn {
        width: 100%;
        padding: 6px 12px;
        margin-top: 16px;
        font-size: 14px;
        border-radius: 3px;
        color: white;
        background-color: #0079bf;

        &.disabled {
          cursor: no-drop;
          background-color: rgba(9, 30, 66, 0.04);
          color: #a5adba;

          &:hover {
            background-color: none;
          }
        }
      }
    }
  }
}
